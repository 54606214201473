import React from "react"

import { Collection } from "../../../../../types"

import { withCollectionListItem } from "./withCollectionListItem"
import { Image } from "../../../Image/Image"
import { H5 } from "../../../Styled/Text"
import { ListItemCard, ListItemContent, ListItemDetails, ListItemHeading, ListItemLink } from "../../CollectionStyles"

interface Props {
  collection: Collection
  image: any
  locales: any
  screenWidth: boolean
  verticalSpacing: boolean
  url: string
  width: string
}

export const CollectionListItem = withCollectionListItem(({ collection, image, locales, screenWidth, url, verticalSpacing, width }: Props) => (
  <ListItemCard screenWidth={screenWidth} verticalSpacing={verticalSpacing} width={width} className={`group`}>
    <ListItemContent>
      <ListItemLink to={url} draggable={false}>
        <Image alt={image?.alt || `${collection?.title} image`} ratio={`2/3`} src={image?.src || image?.originalSrc || image} />
      </ListItemLink>
    </ListItemContent>

    <ListItemDetails>
      <H5>
        <ListItemHeading>{`${locales.title} – `}</ListItemHeading>
        <span>{collection.title}</span>
      </H5>
    </ListItemDetails>
  </ListItemCard>
))
