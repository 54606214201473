import React from "react"

import { useAnalytics } from "@hooks/useAnalytics"
import { useShopify } from "@hooks/useShopify"

export const withCollectionGrid = Component => ({ name = `CollectionGrid`, handles, section, title }) => {
  const { trackPromoImpression, VisibilitySensor } = useAnalytics()
  const { useCollections } = useShopify()
  const collections = useCollections({ handles })

  Component.displayName = name
  return (
    <VisibilitySensor onChange={visible => visible && trackPromoImpression({ ...(section?.analytics || {}) })} partialVisibility={true}>
      <Component collections={collections} title={title} />
    </VisibilitySensor>
  )
}
