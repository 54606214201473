import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { Container } from "../Styled/Container"
import { H2, I, P } from "../Styled/Text"
import { TextButton } from "../Styled/Button"

export const CollectionMobileHeader = tw.div`sticky z-20 mt-5 p-1-2 top-0 bg-light flex md:hidden items-center justify-between`
export const CollectionMobileHeaderColumn = styled.div`
  ${tw`w-full`}
  ${props => (props.align === `right` ? tw`text-right` : tw`text-left`)}
`
export const CollectionHeaderWrapper = tw.div`pt-5 md:pt-9 pb-4-8 bg-light md:z-30 relative`
export const CollectionH2 = tw(H2)`text-center font-sans font-normal leading-relaxed uppercase text-lg  md:text-lg`
export const CollectionDescription = tw(P)`text-center font-normal leading-relaxed p-1-6 max-w-md m-auto`
export const CollectionTextButton = tw(TextButton)`text-sm hover:no-underline`

export const ListContainer = tw(Container)`py-12`
export const ListH2 = tw(H2)`text-center font-sans font-normal leading-relaxed uppercase mb-4-8`
export const ListI = tw(I)`font-display font-normal`
export const ListSection = tw.section`flex flex-wrap -mx-0-6 -my-0-8`

export const ListItemCard = styled.div`
  ${tw`w-full`}
  ${props => (props.width === "1/2" ? tw`md:w-1/2` : props.width === "1/3" ? tw`md:w-1/3` : props.width === "1/4" ? tw`md:w-1/4` : ``)}
  ${props => (props.screenWidth ? tw`pr-1-2 md:px-0-6` : tw`px-0-6`)}
  ${props => (props.verticalSpacing ? tw`py-0-8` : ``)}
`
export const ListItemContent = tw.div`relative`
export const ListItemDetails = tw.div`mt-1-4 text-left`
export const ListItemHeading = tw.span`font-sans`
export const ListItemLink = tw(Link)`block relative`
