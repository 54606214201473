import React from "react"

import { withCollectionGrid } from "./withCollectionGrid"
import { CollectionListItem } from "../../Collection/List/Item/CollectionListItem"
import { Section, StyledH5, Row } from "./CollectionGridStyles"

interface Props {
  collections: Array<any>
  title: string
}

export const CollectionGrid = withCollectionGrid(({ collections, title }: Props) => (
  <Section>
    {title && <StyledH5>{title}</StyledH5>}

    <Row>
      {collections?.map(collection => (
        <CollectionListItem key={collection.id} collection={collection} screenWidth width={`1/4`} />
      ))}
    </Row>
  </Section>
))

export default CollectionGrid
