import React from "react"

import { useSanity } from "@hooks/useSanity"

export const withError = Component => ({ name = `Error`, page, ...props }) => {
  const { textNormaliser } = useSanity()

  const content = {
    ...page,
    message: textNormaliser(page.message),
    title: textNormaliser(page.title),
  }

  Component.displayName = name
  return <Component {...props} content={content} />
}
