import React from "react"
import { graphql } from "gatsby"

import { Error as Page } from "@components/Error/Error"

export const query = graphql`
  query {
    page: sanityNotFoundPage {
      featuredCollections: _rawFeaturedCollections(resolveReferences: { maxDepth: 10 })
      message: _rawMessage(resolveReferences: { maxDepth: 10 })
      title: _rawTitle(resolveReferences: { maxDepth: 10 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
