import React from "react"

import { useLanguage } from "@hooks/useLanguage"
import { useRoutes } from "@hooks/useRoutes"

export const withCollectionListItem = Component => ({ name = `CollectionListItem`, collection, screenWidth, verticalSpacing, width }) => {
  const locales = useLanguage(`collection`)
  const { linkResolver } = useRoutes()

  const image = collection?.image || collection?.products?.find(product => product?.images?.length > 0)?.images[0]
  const url = linkResolver(collection, `collection`)?.url

  Component.displayName = name
  return (
    <Component
      collection={collection}
      image={image}
      locales={locales}
      screenWidth={screenWidth}
      url={url}
      verticalSpacing={verticalSpacing}
      width={width}
    />
  )
}
